<template>
  <footer class="app-footer app-footer-filesmarttax">
    <div class="container">
      <div class="app-footer-row">
        <div class="app-footer-col app-footer-col-big">
          <div class="app-footer-text">
            <div class="app-footer-icon-wrapper">
              <router-link class="app-footer-icon" to="/">
                <logo variant="trans1" />
              </router-link>
            </div>

            <p>Empowering you to be smart with your finances all year-round.</p>
          </div>

          <ul class="app-footer-icons">
            <li class="app-footer-icon-wrapper">
              <inline-image
                :width="74"
                :height="53"
                name="bbb"
                :formats="['webp', 'png']"
                alt="BBB Accredited Business"
                :sizes="4" />
            </li>

            <li class="app-footer-icon-wrapper">
              <inline-image
                :width="163"
                :height="45"
                name="ssl"
                :formats="['webp', 'png']"
                alt="100% Secure SSL Transaction"
                :sizes="4" />
            </li>

            <li class="app-footer-icon-wrapper">
              <inline-image
                :width="191"
                :height="53"
                name="encrypted"
                :formats="['webp', 'png']"
                alt="100% encrypted private connection"
                :sizes="4" />
            </li>
          </ul>
        </div>

        <div class="app-footer-col app-footer-col-small">
          <ul class="app-footer-col-socials app-footer-col-socials-mobile">
            <li class="app-footer-col-social">
              <a href="mailto:test@test.test">
                <svg-icon name="socials/email" />
              </a>
            </li>

            <li class="app-footer-col-social">
              <a href="https://linkedin.com/">
                <svg-icon name="socials/linkedin" />
              </a>
            </li>

            <li class="app-footer-col-social">
              <a href="https://www.instagram.com/">
                <svg-icon name="socials/instagram" />
              </a>
            </li>

            <li class="app-footer-col-social">
              <a href="https://www.facebook.com/">
                <svg-icon name="socials/facebook" />
              </a>
            </li>
          </ul>

          <ul class="app-footer-col-links">
            <li class="app-footer-col-link-wrapper">
              <a class="app-footer-col-link" href="/terms-of-use"> Terms & Conditions </a>
            </li>

            <li class="app-footer-col-link-wrapper">
              <a class="app-footer-col-link" href="/privacy-policy"> Privacy Policy </a>
            </li>

            <li class="app-footer-col-link-wrapper">
              <a class="app-footer-col-link" href="/refund-policy"> Refund Policy </a>
            </li>

            <li class="app-footer-col-link-wrapper">
              <b-link
                class="app-footer-col-link"
                href="https://filesmart.hiringplatform.com/list/careers"
                target="_blank">
                Careers
              </b-link>
            </li>
          </ul>

          <ul class="app-footer-col-socials app-footer-col-socials-desktop">
            <li class="app-footer-col-social">
              <a href="mailto:test@test.test">
                <svg-icon name="socials/email" />
              </a>
            </li>

            <li class="app-footer-col-social">
              <a href="https://linkedin.com/">
                <svg-icon name="socials/linkedin" />
              </a>
            </li>

            <li class="app-footer-col-social">
              <a href="https://www.instagram.com/">
                <svg-icon name="socials/instagram" />
              </a>
            </li>

            <li class="app-footer-col-social">
              <a href="https://www.facebook.com/">
                <svg-icon name="socials/facebook" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="app-footer-row">
        <div class="d-flex flex-column w-100 align-items-center">
          <div class="app-footer-copyright">
            <div>&copy; {{ hostname }}. All right reserved {{ year }} {{ version }}.</div>
          </div>
          <div v-if="sessionInfo !== ''" class="app-footer-funnel">
            <div>{{ sessionInfo }}</div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

import InlineImage from "@/components/inline-image.vue";
import logo from "@/components/logo/index.vue";
import SvgIcon from "@/components/svg-icon/index.vue";
import { useAuthStore } from "@/store/auth.store";
import { useAppStateStore } from "@/store/state.store";
import { getSiteBrandFromConfig, getSiteHost } from "@/util/helpers";

export default defineComponent({
  name: "FooterFilesmarttax",
  components: {
    "inline-image": InlineImage,
    logo,
    "svg-icon": SvgIcon
  },
  props: {
    authorized: {
      type: Boolean,
      default: false
    },
    offer: {
      type: String as PropType<string | undefined>,
      required: false
    }
  },
  setup() {
    const authStore = useAuthStore();
    const appStateStore = useAppStateStore();

    return { authStore, appStateStore };
  },
  data() {
    return {
      year: new Date().getFullYear(),
      hostname: window.location.hostname,
      version: import.meta.env.VITE_VERSION
    };
  },
  computed: {
    extensionUrl(): string {
      if (this.authorized) {
        return `/extension/1?${this.authStore.registrationSource}`;
      } else {
        return "/registration";
      }
    },
    sessionInfo(): string {
      const currentOffer = this.offer ? ` / ${this.offer}` : "";
      const funnel = this.authStore.funnel;

      if (this.authStore.userData?.email && this.authStore.userData.email !== "") {
        return `Funnel: ${this.appStateStore.settings.funnel ?? funnel.toUpperCase()}${currentOffer}`;
      }

      return `Funnel: ${funnel.toUpperCase()}`;
    },
    engineHost(): string | undefined {
      const brand = getSiteBrandFromConfig();
      const host = getSiteHost(brand);

      return host;
    }
  }
});
</script>
