<template>
  <header class="app-header app-header-filedirectnet app-header-dashboard">
    <div class="app-header-supheader"></div>
    <b-navbar :toggleable="false" container>
      <b-navbar-brand>
        <router-link :to="extensionUrl" exact>
          <logo />
        </router-link>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse">
        <div class="hamburger hamburger-slider">
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
        </div>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ms-auto">
          <b-nav-text>
            <b-button variant="primary" @click="boirFilingHandler">
              <svg-icon name="file-import" />
              New BOIR Filing
            </b-button>
            <button class="header-toggler" @click="toggleSidebar">
              <div class="hamburger hamburger-slider">
                <div class="hamburger-box">
                  <div v-if="!sidebarOpen" class="hamburger-inner"></div>
                  <svg-icon v-else name="dashboard/sidebar/arrow-right" />
                </div>
              </div>
            </button>
          </b-nav-text>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import logo from "@/components/logo/index.vue";
import SvgIcon from "@/components/svg-icon/index.vue";
import { useBoir } from "@/composables/boir/use-boir";
import { useAuthStore } from "@/store/auth.store";
import { useAppStateStore } from "@/store/state.store";
import { FLOW, ROUTE_NAMES } from "@/util/routes/route-names";

export default defineComponent({
  name: "DashboardHeaderFileDirect",
  components: {
    "svg-icon": SvgIcon,
    logo
  },
  setup() {
    const authStore = useAuthStore();
    const appStateStore = useAppStateStore();
    const boir = useBoir();

    return { authStore, appStateStore, boir };
  },
  computed: {
    sidebarOpen(): boolean {
      return this.appStateStore.isDashboardSidebarOpen;
    },
    extensionUrl(): string {
      if (this.$route.meta.flow === FLOW.dfyReturn) {
        if (this.$route.name === ROUTE_NAMES.doneDorYou) {
          return "/tax-return-preparation/done-for-you";
        } else {
          return "/tax-return-preparation/done-for-you-v2";
        }
      } else if (this.authStore.isAuthorized) {
        return `/dashboard`;
      } else {
        return "/";
      }
    }
  },
  methods: {
    toggleSidebar() {
      this.appStateStore.toggleDashboardSidebarOpen();
    },
    boirFilingHandler(): void {
      this.boir.toBoirFiling();
    }
  }
});
</script>
<style lang="scss" src="./styles.scss" scoped></style>
