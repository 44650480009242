import * as Sentry from "@sentry/vue";

import { cookieNames, EVENTS } from "@/config/constants";
import type { DtoProduct } from "@/services/api";
import { type AuthState, useAuthStore } from "@/store/auth.store";
import { usePostHogStore } from "@/store/posthog.store";
import { getCookie } from "@/util/cookies";
import { getSessionStorageObject, type PreFillingUserData, sessionStorageNames } from "@/util/storage";

const productPrice = (product: DtoProduct) => {
  const { price = 0, product_type } = product;

  if (product_type === "auto-extend") return 0;

  return price / 100;
};

export const addToDataLayer = (data: Record<string, any>): void => {
  try {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push(data);
  } catch (ex) {
    console.error(ex);
  }
};

export const addProductsToDataLayer = (products: DtoProduct[], funnel: string): void => {
  const { userData } = useAuthStore();
  const postHogStore = usePostHogStore();

  addToDataLayer({
    event: "add_to_cart",
    ph: postHogStore.getTrackingData,
    funnel,
    user: {
      email: userData.email,
      phone: userData.phone,
      first_name: userData.first_name,
      last_name: userData.last_name
    },
    ecommerce: {
      items: products.map((product: DtoProduct) => ({
        item_id: product.sku,
        item_name: product.name,
        code: product.code,
        item_category: product.product_type,
        item_category2: product.has_trial,
        is_trial: product.has_trial,
        currency: "USD",
        price: productPrice(product),
        quantity: 1
      }))
    }
  });

  addLeadEventToGTM(2);
};

export const addPurchaseToDataLayer = (
  products: DtoProduct[],
  transactionId: string,
  funnel: string,
  revenue?: number
): void => {
  addToDataLayer({
    ecommerce: null
  });

  const authStore: AuthState = useAuthStore();
  const postHogStore = usePostHogStore();
  const { userData } = authStore;

  addToDataLayer({
    event: "purchase",
    ph: postHogStore.getTrackingData,
    funnel,
    user: {
      email: userData.email,
      phone: userData.phone,
      first_name: userData.first_name,
      last_name: userData.last_name
    },
    ecommerce: {
      transaction_id: transactionId,
      value: revenue ? revenue / 100 : 0,
      currency: "USD",
      items: products.map((product: DtoProduct) => ({
        item_id: product.sku,
        item_name: product.name,
        item_category: product.product_type,
        item_category2: product.has_trial,
        is_trial: product.has_trial,
        code: product.code,
        g1_conversion_label: product.g1_conversion_label,
        g2_conversion_label: product.g2_conversion_label,
        price: productPrice(product),
        quantity: 1
      }))
    }
  });

  addLeadEventToGTM(18);
};

export const addBeginCheckoutToDataLayer = (products: DtoProduct[], funnel: string): void => {
  const postHogStore = usePostHogStore();

  addToDataLayer({
    event: "begin_checkout",
    ph: postHogStore.getTrackingData,
    funnel,
    ecommerce: {
      items: products.map((product: DtoProduct) => ({
        item_id: product.sku,
        item_name: product.name,
        code: product.code,
        item_category: product.product_type,
        item_category2: product.has_trial,
        is_trial: product.has_trial,
        currency: "USD",
        price: productPrice(product),
        quantity: 1
      }))
    }
  });
};

export const addRegistrationToDataLayer = (userId: string, provider: string, funnel: string): void => {
  const authStore: AuthState = useAuthStore();
  const postHogStore = usePostHogStore();
  const { userData } = authStore;

  addToDataLayer({
    event: "sign_up",
    step: "one",
    ph: postHogStore.getTrackingData,
    funnel: funnel,
    user: {
      email: userData.email,
      phone: userData.phone,
      first_name: userData.first_name,
      last_name: userData.last_name
    },
    user_id: userId,
    method: provider === "email" ? provider : `${provider}-oauth`
  });
};

export const addLeadEventToGTM = (id: number): void => {
  const eventId = `event_${id.toString().padStart(2, "0")}`;
  const event = EVENTS[eventId];
  const authStore = useAuthStore();
  const postHogStore = usePostHogStore();

  if (!event) {
    return;
  }

  addToDataLayer({
    event: "generate_lead",
    funnel: authStore.funnel,
    ph: postHogStore.getTrackingData,
    non_ecomm: {
      item_id: eventId,
      item_name: event.eventName,
      code: event.eventCode,
      g1_conversion_label: event.g1ConversionLabel,
      g2_conversion_label: event.g2ConversionLabel,
      item_price: 0
    }
  });
};

export const addSelectPlanToGTM = (): void => {
  addToDataLayer({
    event: "select_plan",
    ecommerce: {
      items: [
        {
          item_id: "event_37",
          item_name: "Business Platinum Selection",
          code: "extension-business-platinum",
          item_category: "business",
          item_category2: false,
          is_trial: false,
          currency: "USD",
          price: 0,
          quantity: 1
        }
      ]
    },
    ["gtm.uniqueEventId"]: 4181
  });
};

const DEFAULT_FILEDIRECT_URL = import.meta.env.VITE_BOIR_FORM_URL;

export const redirectToFiledirectForm = (link?: string): void => {
  const trackingCookies: string[] = [cookieNames.redtrackClickId, cookieNames.redtrackCompanyId, cookieNames.ingestId];

  try {
    const url = new URL(link ?? DEFAULT_FILEDIRECT_URL);

    trackingCookies.forEach((name) => {
      const value = getCookie(name);

      if (!value) {
        return;
      }

      url.searchParams.set(name, value);
    });

    const postHogStore = usePostHogStore();

    if (postHogStore.getTrackingData.session_id) {
      url.searchParams.set("session_id", postHogStore.getTrackingData.session_id);
    }

    const preFillingUserData = getSessionStorageObject<PreFillingUserData>(sessionStorageNames.preFillingUserData);
    const companyName = preFillingUserData?.name;

    if (companyName) {
      url.searchParams.set("business_name", companyName);
    }

    window.location.href = url.toString();
  } catch (error: unknown) {
    Sentry.captureMessage(
      `Error trying to redirect user to filedirect form. url: ${link}, error: ${JSON.stringify(error)}`
    );
  }
};
