<template>
  <footer class="app-footer app-footer-filedirectnet">
    <div class="app-footer-bottom">
      <div class="container">
        <div class="row footer-main">
          <div class="col-12 col-md-6 text-center text-md-start footer-small-col">
            <div class="app-footer-logo">
              <router-link to="/" exact>
                <logo variant="white" class="white-logo" />
              </router-link>
            </div>
          </div>
          <div
            class="col-12 col-md-6 justify-content-center justify-content-md-end d-flex align-items-start footer-big-col desktop-only">
            <div class="app-footer-logos">
              <inline-image
                :width="73"
                :height="52"
                name="bbb"
                :formats="['webp', 'png']"
                alt="BBB Accredited Business"
                :sizes="4" />

              <inline-image
                :width="161"
                :height="44"
                name="ssl"
                :formats="['webp', 'png']"
                alt="100% Secure SSL Transaction"
                :sizes="4" />

              <svg-icon name="encrypted" class="icon-encrypted" />
            </div>
          </div>
        </div>

        <div class="row mt-md-0 mt-lg-4 footer-additional">
          <div class="col-12 col-md-6 footer-small-col">
            <div class="app-footer-disclaimer">
              <div>IMPORTANT DISCLAIMERS:</div>
              <div>
                FileDirect.net is a d/b/a of Method Marketing, LLC and is a private company and FinCEN authorized API
                participant. Our software helps to file your BOIR and tax filing submissions, offering a state of the
                art user interface, time saving features and full customer support.
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-2 footer-menus footer-big-col">
            <ul class="app-footer-links list-unstyled">
              <li>
                <router-link to="/">Home</router-link>
              </li>
              <li>
                <router-link to="/features">Features</router-link>
              </li>
              <li v-if="!authorized" class="mobile-no-border">
                <router-link to="/registration">Registration</router-link>
              </li>
              <li v-if="!authorized">
                <router-link to="/login">Login</router-link>
              </li>
              <li>
                <router-link to="/contact-us">Contact Us</router-link>
              </li>
            </ul>
            <ul class="app-footer-links list-unstyled">
              <li>
                <router-link to="/faq">FAQ</router-link>
              </li>
              <li class="mobile-no-border">
                <a href="/terms-of-use">Terms of Use</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/refund-policy">Refund Policy</a>
              </li>
              <li v-if="authorized">
                <router-link to="/logout">Logout</router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="mobile-only">
          <div class="justify-content-center justify-content-md-end d-flex align-items-start">
            <div class="app-footer-logos">
              <inline-image
                :width="47"
                :height="33"
                name="bbb"
                :formats="['webp', 'png']"
                alt="BBB Accredited Business"
                :sizes="4" />

              <inline-image
                :width="102"
                :height="28"
                name="ssl"
                :formats="['webp', 'png']"
                alt="100% Secure SSL Transaction"
                :sizes="4" />

              <svg-icon name="encrypted" class="icon-encrypted" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="app-footer-copyright">
              <div>&copy; {{ brand }}. All right reserved.</div>
              <div>{{ version }} / {{ date }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script lang="ts">
import { format } from "date-fns";
import type { PropType } from "vue";
import { defineComponent } from "vue";

import InlineImage from "@/components/inline-image.vue";
import logo from "@/components/logo/index.vue";
import SvgIcon from "@/components/svg-icon/index.vue";
import { useAuthStore } from "@/store/auth.store";
import { useAppStateStore } from "@/store/state.store";
import { addLeadEventToGTM } from "@/util/tracking";

export default defineComponent({
  name: "FooterFiledirectnet",
  components: {
    "inline-image": InlineImage,
    logo,
    "svg-icon": SvgIcon
  },
  props: {
    authorized: {
      type: Boolean,
      default: false
    },
    offer: {
      type: String as PropType<string | undefined>,
      required: false
    }
  },
  setup() {
    const authStore = useAuthStore();
    const appStateStore = useAppStateStore();

    return { authStore, appStateStore };
  },
  data() {
    return {
      brand: import.meta.env.VITE_BRAND,
      version: import.meta.env.VITE_VERSION,
      date: format(new Date(), "yyyy-MM-dd HH:mm:ss")
    };
  },
  computed: {
    isBasicPage(): boolean {
      const requiresAuth = this.$route.meta?.requiresAuth;

      return !requiresAuth;
    },
    darkFooter(): boolean {
      return this.isBasicPage ?? this.authorized;
    },
    extensionUrl(): string {
      if (this.authorized) {
        return `/extension/1?${this.authStore.registrationSource}`;
      } else {
        return "/registration";
      }
    }
  },
  methods: {
    addLeadEventToGTM
  }
});
</script>
