import { BusinessFormsAPI, FormHelpersAPI, PersonalFormsAPI } from "@/api";
import type {
  DtoF4868CreateRequest,
  DtoF4868CreateResponse,
  DtoF4868FullUpdateRequest,
  DtoF4868Step2Request,
  DtoF4868Step3Request,
  DtoF4868Step4Request,
  DtoF4868Step5Request,
  DtoF4868Step6Request,
  DtoF4868Step7Request,
  DtoF7004CreateRequest,
  DtoF7004CreateResponse,
  DtoF7004Step2Request,
  DtoF7004Step3Request,
  DtoF7004Step4Request,
  DtoF7004Step5Request,
  DtoF7004Step6Request,
  DtoF7004Step7Request,
  DtoF7004Step8Request,
  DtoF7004Step9Request,
  DtoF7004UpdateFormRequest,
  DtoForm4868,
  DtoForm7004,
  F4868TaxFilingPlansRequest,
  F4868TaxFilingPlansResponse
} from "@/services/api";
import { useAuthStore } from "@/store/auth.store";
import { useFormsStore } from "@/store/forms.store";
import { useAppStateStore } from "@/store/state.store";
import type { UserData } from "@/store/types";
import { openWindowForPDFDownload } from "@/util/helpers";
import { getLogger } from "@/util/logger";
import { addPurchaseToDataLayer } from "@/util/tracking";

const log = getLogger("API: Forms");

export const New4868 = async (params: DtoF4868CreateRequest): Promise<DtoF4868CreateResponse | undefined> => {
  try {
    const { data } = await PersonalFormsAPI.createPersonalForm(params);

    return data;
  } catch {
    log.error("Cannot create 4868 form");
  }
};

export const Save4868Step2 = async (formUid: string, params: DtoF4868Step2Request) => {
  log.time("Step2 personal form call");

  const { data } = await PersonalFormsAPI.storePersonalFormStep2(formUid, params);

  log.timeEnd("Step2 personal form call");
  return data;
};

export const Save4868Step3 = async (formUid: string, params: DtoF4868Step3Request) => {
  log.time("Step3 personal form call");

  const { data } = await PersonalFormsAPI.storePersonalFormStep3(formUid, params);

  log.timeEnd("Step3 personal form call");
  return data;
};

export const Save4868Step4 = async (formUid: string, params: DtoF4868Step4Request) => {
  log.time("Step4 personal form call");

  const { data } = await PersonalFormsAPI.storePersonalFormStep4(formUid, params);

  log.timeEnd("Step4 personal form call");

  return data;
};

export const Save4868Step5 = async (formUid: string, params: DtoF4868Step5Request) => {
  log.time("Step5 personal form call");

  const { data } = await PersonalFormsAPI.storePersonalFormStep5(formUid, params);

  log.timeEnd("Step5 personal form call");
  return data;
};

export const Save4868Step6 = async (formUid: string, params: DtoF4868Step6Request) => {
  log.time("Step6 personal form call");

  const { data } = await PersonalFormsAPI.storePersonalFormStep6(formUid, params);

  log.timeEnd("Step5 personal form call");
  return data;
};

export const Save4868Step7 = async (formUid: string, params: DtoF4868Step7Request) => {
  log.time("Step7 personal form call");

  const { data } = await PersonalFormsAPI.storePersonalFormStep7(formUid, params);

  log.timeEnd("Step7 personal form call");
  return data;
};

export const Save4868Full = async (formUid: string, params: DtoF4868FullUpdateRequest) => {
  log.time("Personal form flush call");

  const { data } = await PersonalFormsAPI.updatePersonalForm(formUid, params);

  log.timeEnd("Personal form flush call");
  return data;
};

export const Get4868 = async (formUid: string): Promise<DtoForm4868 | undefined> => {
  try {
    const { data } = await PersonalFormsAPI.getPersonalFormById(formUid);

    return data;
  } catch {
    log.error("Get form 4868 error");
  }
};

export const GetAll4868 = async (): Promise<DtoForm4868[] | undefined> => {
  try {
    const { data } = await PersonalFormsAPI.getAllPersonalForms();

    return data;
  } catch {
    log.error("GetAll4868 error");
  }
};

export const New7004 = async (
  params: DtoF7004CreateRequest,
  userData: UserData
): Promise<DtoF7004CreateResponse | undefined> => {
  const { first_name, last_name } = userData;

  if (!params.first_name && first_name !== "") {
    params.first_name = first_name;
  }
  if (!params.last_name && last_name !== "") {
    params.last_name = last_name;
  }

  const { data } = await BusinessFormsAPI.createBusinessForm(params);

  return data;
};

export const Save7004Step2 = async (formUid: string, params: DtoF7004Step2Request) => {
  log.time("Business form Step2 call");

  const { data } = await BusinessFormsAPI.storeBusinessFormStep2(formUid, params);

  log.timeEnd("Business form Step2 call");

  return data;
};

export const Save7004Step3 = async (formUid: string, params: DtoF7004Step3Request) => {
  log.time("Business form Step3 call");

  const { data } = await BusinessFormsAPI.storeBusinessFormStep3(formUid, params);

  log.timeEnd("Business form Step3 call");

  return data;
};

export const Save7004Step4 = async (formUid: string, params: DtoF7004Step4Request) => {
  log.time("Business form Step4 call");

  const { data } = await BusinessFormsAPI.storeBusinessFormStep4(formUid, params);

  log.timeEnd("Business form Step4 call");

  return data;
};

export const Save7004Step5 = async (formUid: string, params: DtoF7004Step5Request) => {
  log.time("Business form Step5 call");

  const { data } = await BusinessFormsAPI.storeBusinessFormStep5(formUid, params);

  log.timeEnd("Business form Step5 call");

  return data;
};

export const Save7004Step6 = async (formUid: string, params: DtoF7004Step6Request) => {
  log.time("Business form Step6 call");

  const { data } = await BusinessFormsAPI.storeBusinessFormStep6(formUid, params);

  log.timeEnd("Business form Step6 call");

  return data;
};

export const Save7004Step7 = async (formUid: string, params: DtoF7004Step7Request) => {
  log.time("Business form Step7 call");

  const { data } = await BusinessFormsAPI.storeBusinessFormStep7(formUid, params);

  log.timeEnd("Business form Step7 call");

  return data;
};

export const Save7004Step8 = async (formUid: string, params: DtoF7004Step8Request) => {
  log.time("Business form Step8 call");

  const { data } = await BusinessFormsAPI.storeBusinessFormStep8(formUid, params);

  log.timeEnd("Business form Step8 call");

  return data;
};

export const Save7004Step9 = async (formUid: string, params: DtoF7004Step9Request) => {
  log.time("Business form Step9 call");

  const { data } = await BusinessFormsAPI.storeBusinessFormStep9(formUid, params);

  log.timeEnd("Business form Step9 call");

  return data;
};

export const Save7004Full = async (formUid: string, params: DtoF7004UpdateFormRequest) => {
  log.time("Business form FLUSH call");

  const { data } = await BusinessFormsAPI.updateBusinessForm(formUid, params);

  log.timeEnd("Business form FLUSH call");

  return data;
};

export const Get7004 = async (formUid: string): Promise<DtoForm7004 | undefined> => {
  try {
    const { data } = await BusinessFormsAPI.getBusinessFormById(formUid);

    return data;
  } catch {
    log.error("Get form 7004 error");
  }
};

export const GetAll7004 = async (): Promise<DtoForm7004[] | undefined> => {
  try {
    const { data } = await BusinessFormsAPI.getAllBusinessForms();

    return data;
  } catch {
    log.error("GetAll7004 Error");
  }
};

export const EFile4868 = async (formUid: string) => {
  log.time("Get settings call for EFile4868");

  const { data } = await PersonalFormsAPI.willEFilePersonalForm(formUid);

  log.timeEnd("Get settings call for EFile4868");

  return data;
};

export const EFile7004 = async (formUid: string) => {
  log.time("Get settings call for EFile7004");

  const { data } = await BusinessFormsAPI.willEFileBusinessForm(formUid);

  log.timeEnd("Get settings call for EFile7004");

  return data;
};

export const setTaxFilingPlans = async (request: F4868TaxFilingPlansRequest): Promise<F4868TaxFilingPlansResponse> => {
  log.time("Setting Tax Filing Plans");

  const { data } = await FormHelpersAPI.setTaxFilingPlans(request);

  log.timeEnd("Setting Tax Filing Plans");

  return data;
};

export const PrintFormPDF = (funnel: string) => {
  const formsStore = useFormsStore();
  const authStore = useAuthStore();
  const appStateStore = useAppStateStore();

  const formId = formsStore.form.uid ?? "";

  let fileName = "form_4868.pdf";
  let formType = "f4868";

  if (formsStore.form.type === "business") {
    formType = "f7004";
    fileName = "form_7004.pdf";
  }

  const token = authStore.authToken;

  addPurchaseToDataLayer(
    appStateStore.settings.products?.filter((product) => product.product_type === "print") ?? [],
    formId,
    funnel,
    0
  );

  openWindowForPDFDownload(`/api/v1/documents/${formType}/${formId}/${fileName}?jt=${token}`);
};
