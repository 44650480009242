import type { RouteLocationNormalized } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

import { ROUTE_NAMES } from "@/util/routes/route-names";
import { ROUTES } from "@/util/routes/routes";

export const isRouteSetUsername = (route?: RouteLocationNormalized): boolean => {
  if (!route) {
    return window.location.pathname.includes("/registration/create-username");
  }

  return route.name === ROUTE_NAMES.registration && route.params.step === "create-username";
};

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to: RouteLocationNormalized) {
    if (to.hash) {
      if (to.hash === "#_=_") return { x: 0, y: 0 };

      return { el: to.hash, behavior: "smooth" };
    } else {
      return { left: 0, top: 0 };
    }
  },
  routes: ROUTES
});
