import * as Sentry from "@sentry/vue";
import type { AxiosError } from "axios";

export const isAxiosError = (candidate: any): candidate is AxiosError<{ msg?: string }, any> => {
  return candidate.isAxiosError === true;
};

export type CatchError<T = unknown> = T | AxiosError;

export const handleError = (
  err: CatchError,
  callback?: (err: AxiosError<{ msg?: string; detail?: string }>) => void
) => {
  if (isAxiosError(err) && callback) {
    return callback(err);
  } else {
    Sentry.captureException(err);
  }
};
