<template>
  <sticky-bar id="SB-02" :used="alreadyUsed" position="bottom" :hidden="isHidden" @visible="onVisible">
    <div>
      <span class="text">🚨 FLASH DEAL 🚨 Submit NOW for substantial savings. Only $19.95! </span>
      <span v-if="sbTimer" class="timer">
        Ends in <span class="tick">{{ formatTime(remainingTime) }}</span>
      </span>
    </div>
    <b-button v-if="!alreadyUsed" :disabled="isLoading" variant="primary" @click="addToCart">Grab Deal</b-button>
    <b-button :disabled="isLoading" variant="primary" @click="hideStickyBar">No, thanks</b-button>
  </sticky-bar>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import StickyBar from "@/components/sticky-bar/sticky-bar.vue";
import { useEventBus } from "@/composables/use-event-bus";
import { cookieNames, THREE_DAYS_IN_MS, THREE_MONTHS_IN_MS } from "@/config/constants";
import type { DtoProduct } from "@/services/api";
import { useAuthStore } from "@/store/auth.store";
import { useCartStore } from "@/store/cart.store";
import { useFormsStore } from "@/store/forms.store";
import { useAppStateStore } from "@/store/state.store";
import { getCookie, setCookie } from "@/util/cookies";
import { addProductsToDataLayer } from "@/util/tracking";

export default defineComponent({
  name: "SB02",
  components: {
    "sticky-bar": StickyBar
  },
  setup() {
    const authStore = useAuthStore();
    const appStateStore = useAppStateStore();
    const formsStore = useFormsStore();
    const cartStore = useCartStore();
    const eventBus = useEventBus();

    return { authStore, formsStore, cartStore, appStateStore, eventBus };
  },
  data() {
    return {
      remainingTime: 59,
      sbTimer: null as number | null,
      alreadyUsed: getCookie(cookieNames.stickyBar02Used) === "1",
      isVisible: false,
      nextPage: "",
      targetOffer: window.localStorage.getItem("prev-funnel-page")
    };
  },
  computed: {
    isLoading(): boolean {
      return this.appStateStore.isLoading;
    },
    products(): DtoProduct[] {
      return this.appStateStore.settings.products ?? [];
    },
    isHidden(): boolean {
      if (getCookie(cookieNames.stickyBar02Used) === "1") {
        return true;
      }

      return this.alreadyUsed;
    }
  },
  mounted() {
    this.eventBus.$emit("SB-02-loaded");
  },
  beforeUnmount() {
    if (this.sbTimer) {
      window.clearInterval(this.sbTimer);
      this.sbTimer = null;
    }
  },
  methods: {
    formatTime(seconds: number): string {
      const formattedSeconds = seconds.toString().padStart(2, "0");

      return `00:${formattedSeconds}`;
    },
    onVisible(nextPage: string): void {
      this.isVisible = true;
      this.nextPage = nextPage;
      if (!this.sbTimer) {
        this.sbTimer = window.setInterval(() => {
          if (this.remainingTime === 0) {
            if (this.sbTimer) window.clearInterval(this.sbTimer);
            this.sbTimer = null;
            this.remainingTime = 59;
            return;
          }
          this.remainingTime -= 1;
        }, 1000);
      }
    },

    getProduct(sku: string): DtoProduct | undefined {
      if (!this.products) return;

      return this.products.find((p) => p.sku === sku);
    },
    productExist(sku: string): boolean {
      if (!this.products) return false;

      return this.products.some((p) => p.sku === sku);
    },
    async addToCart(): Promise<void> {
      const form = this.formsStore.form;
      const formType = form.type ?? "personal";
      const step = formType === "personal" ? 9 : 10;

      let nextPage = "pre-offer";
      if (this.nextPage !== "") {
        nextPage = this.nextPage;
      }

      this.eventBus.$emit("sticky-button-click");

      await this.formsStore.applyCoupon("PR24");
      await this.appStateStore.getSettings();

      setCookie(cookieNames.stickyBar02Used, "1", THREE_MONTHS_IN_MS);

      this.alreadyUsed = true;

      await this.addFormProducts();

      this.nextPage = "";
      this.$router.replace(`/extension/${formType}/${step}/${nextPage}`);
    },
    hideStickyBar(): void {
      setCookie(cookieNames.stickyBar02Used, "1", THREE_DAYS_IN_MS);
      this.alreadyUsed = true;
    },
    async addFormProducts() {
      let productToAdd = "pr02";
      if (this.formsStore.form.type === "business") {
        productToAdd = "pr03";
      }
      const product = this.getProduct(productToAdd);
      if (!product) {
        console.error("Failed to get PR02 products by sku");
        return;
      }

      addProductsToDataLayer([product], this.authStore.funnel);

      const formUid = this.formsStore.form.uid;

      if (!formUid) return;

      await this.cartStore.updateFormProductsDelayed([{ purchase_id: formUid, sku: product.sku }]);
    }
  }
});
</script>
