<template>
  <header class="app-header app-header-efiletaxnet" :class="{ 'app-header-grey': isHeaderGrey }">
    <b-navbar toggleable="md" container>
      <b-navbar-brand>
        <router-link :to="extensionUrl" exact>
          <logo />
        </router-link>
      </b-navbar-brand>
      <b-navbar-toggle v-if="showLinks" target="nav-collapse">
        <div class="hamburger hamburger-slider">
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
        </div>
      </b-navbar-toggle>
      <b-collapse v-if="showLinks" id="nav-collapse" is-nav>
        <b-navbar-nav class="ms-auto">
          <b-nav-item to="/" exact>Home</b-nav-item>
          <b-nav-item to="/tax-extension-personal"> Tax Extension </b-nav-item>
          <li class="nav-item desktop-only" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
            <div
              class="nav-link nav-popover"
              :class="{ active: isTaxReturnPreparationActive }"
              aria-haspopup="true"
              :aria-expanded="isTaxPreparationHovered">
              <div class="nav-item-dropdown-title">Tax Prep</div>
            </div>

            <ul class="nav-item-dropdown-items">
              <li class="nav-item-dropdown-item">
                <b-link class="nav-item-dropdown-item-link" to="/tax-return-preparation/do-it-yourself">
                  Do-It-Yourself
                </b-link>
              </li>

              <li class="nav-item-dropdown-item">
                <b-link class="nav-item-dropdown-item-link" to="/tax-return-preparation/done-for-you-v2">
                  Done For You
                </b-link>
              </li>
            </ul>
          </li>

          <li class="nav-item mobile-only">
            <div class="nav-item-collapse-wrapper">
              <button
                v-b-toggle.taxprep-collapse
                aria-label="Toggle tax preparation menu"
                class="nav-link nav-popover"
                :class="{ active: isTaxReturnPreparationActive }">
                Tax Prep
              </button>

              <b-collapse id="taxprep-collapse" class="nav-item-collapse">
                <b-nav-item v-b-toggle.nav-collapse to="/tax-return-preparation/do-it-yourself">
                  Do-It-Yourself
                </b-nav-item>
                <b-nav-item v-b-toggle.nav-collapse to="/tax-return-preparation/done-for-you-v2">
                  Done For You
                </b-nav-item>
              </b-collapse>
            </div>
          </li>
          <b-nav-item to="/blog">Blog</b-nav-item>
          <b-nav-item to="/faq">FAQs</b-nav-item>
          <b-nav-item to="/contact-us">Contact Us</b-nav-item>
          <b-nav-item v-if="!authorized" to="/registration" :active="isRegistration">Registration</b-nav-item>
          <b-nav-item v-if="authorized" to="/dashboard">Dashboard</b-nav-item>
          <b-nav-item v-if="authorized" to="/logout">Logout</b-nav-item>
          <b-nav-item v-else to="/login">Login</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import logo from "@/components/logo/index.vue";

export default defineComponent({
  name: "EFileTaxNetHeader",
  components: {
    logo
  },
  props: {
    authorized: {
      type: Boolean,
      default: false
    },
    showLinks: {
      type: Boolean,
      default: true
    },
    extensionUrl: {
      type: String,
      required: true
    },
    blogUrl: {
      type: String,
      required: false
    },
    hideSubmitExtBtn: {
      type: Boolean,
      default: false
    },
    isHeaderGrey: {
      type: Boolean,
      default: false
    },
    taxReturnPreparationLink: {
      type: String
    },
    isTaxReturnPreparationActive: {
      type: Boolean,
      default: false
    },
    isRegistration: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      isTaxPreparationHovered: false
    };
  },
  methods: {
    handleMouseEnter() {
      this.isTaxPreparationHovered = true;
    },
    handleMouseLeave() {
      this.isTaxPreparationHovered = false;
    }
  }
});
</script>
