import { DIYFilingsAPI } from "@/api/index";
import type { DiyNewTaxFilingRequest, DiyReturnURLResponse, DiySettings, DiyTaxReturn } from "@/services/api";
import { getLogger } from "@/util/logger";

const log = getLogger("API: Column tax");

export const getDiySettings = async (): Promise<DiySettings> => {
  log.time("Get Diy Settings");

  const { data } = await DIYFilingsAPI.diySettingsGet();

  log.timeEnd("Get Diy Settings");

  return data;
};

export const diyTaxFilingPost = async (request: DiyNewTaxFilingRequest): Promise<DiyTaxReturn> => {
  log.time("Sign Diy Filing");

  const { data } = await DIYFilingsAPI.diyFilingPost(request);

  log.time("Sign Diy Filing");

  return data;
};

export const getDiyReturns = async (): Promise<DiyTaxReturn[]> => {
  log.time("Get Diy Returns");

  const { data } = await DIYFilingsAPI.diyFilingsGet();

  log.timeEnd("Get Diy Returns");

  return data;
};

export const diyFilingIdPut = async (filingId: string): Promise<DiyTaxReturn> => {
  log.time("Put Diy Filing");

  const { data } = await DIYFilingsAPI.diyFilingFilingIdPut(filingId);

  log.timeEnd("Put Diy Filing");

  return data;
};

export const getDiyTaxReturnURL = async (): Promise<DiyReturnURLResponse> => {
  log.time("Get Diy Return Url");

  const { data } = await DIYFilingsAPI.diyReturnUrlGet();

  log.timeEnd("Get Diy Return Url");

  return data;
};
