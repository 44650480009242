<template>
  <b-modal v-model="showDialog" :title="!timeIsOut ? 'Session timeout' : 'Session expired'" @close="hideDialog">
    <template #default>
      <div class="text-center">
        <template v-if="!timeIsOut">
          <svg-icon name="clock" style="font-size: 5em" class="clr-yellow" />
          <br /><br />
          <p>Your session will expire in</p>
          <h5>{{ timeLeft.minutes }} minutes and {{ timeLeft.seconds }} seconds</h5>
          <p>Please click Continue to keep working or log out.</p>
        </template>
        <template v-else>
          <logo />
          <div class="mt-4">
            <strong>Your session has expired</strong>
            <div>We have logged you out of the system for your security.</div>
          </div>
          <div>Your session expired at {{ sessionExpired }}</div>
        </template>
      </div>
    </template>
    <template v-if="!timeIsOut" #footer>
      <div class="app-form-buttons">
        <b-button variant="outline-primary" @click="logoutAction"> Log out </b-button>
        <b-button variant="primary" @click="continueSession"> Continue </b-button>
      </div>
    </template>
    <template v-else #footer>
      <div class="app-form-buttons">
        <b-button variant="primary" @click="logoutAction"> Log in to start a new session </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import logo from "@/components/logo/index.vue";
import SvgIcon from "@/components/svg-icon/index.vue";
import { TEN_MINUTES_IN_SECONDS } from "@/config/constants";
import { useAuthStore } from "@/store/auth.store";
import { LogoutHlp } from "@/util/auth";
import { getLogger } from "@/util/logger";

const logger = getLogger("SessionModal");

export default defineComponent({
  name: "SessionModal",
  components: {
    logo,
    "svg-icon": SvgIcon
  },
  setup() {
    const authStore = useAuthStore();

    return { authStore };
  },
  data() {
    return {
      timeIsOut: false,
      timeoutAfter: TEN_MINUTES_IN_SECONDS,
      interval: null as number | null,
      sessionExpired: "",
      seconds: 0,
      timerStartedAt: new Date().getTime()
    };
  },
  computed: {
    showDialog(): boolean {
      return this.authStore.authTokenExpShowDialog;
    },
    timeLeft() {
      return this.formatTime(Math.max(this.timeoutAfter - this.seconds, 0));
    }
  },
  watch: {
    showDialog(open: boolean): void {
      if (open) {
        if (this.authStore.authTokenExpShowExpMinutes && this.authStore.authTokenExpShowExpMinutes > 0) {
          this.timeoutAfter = this.authStore.authTokenExpShowExpMinutes * 60 - 1;
        }
        this.startTimer();
      }
    },
    seconds(sec: number): void {
      if (sec >= this.timeoutAfter) {
        this.clearTimer();

        this.timeIsOut = true;
        this.sessionExpired = new Date().toLocaleTimeString();
      }
    }
  },
  unmounted() {
    this.clearTimer();
  },
  created() {
    logger.info("initializing...");
  },
  methods: {
    onClose(): void {
      this.closeDialog();
    },
    clearTimer(): void {
      if (this.interval) {
        window.clearInterval(this.interval);
      }

      this.seconds = 0;
      this.timeIsOut = false;
    },
    startTimer(): void {
      this.clearTimer();
      this.timerStartedAt = new Date().getTime();

      this.interval = window.setInterval(() => {
        this.seconds = (new Date().getTime() - this.timerStartedAt) / 1000;
        if (this.seconds >= this.timeoutAfter) {
          this.clearTimer();
          this.seconds = this.timeoutAfter;

          if (this.interval) {
            window.clearInterval(this.interval);
          }
        }
      }, 1000);
    },
    formatTime(time: number) {
      let minutes = Math.floor(time / 60);
      minutes = minutes < 0 ? 0 : minutes;

      let s = Math.floor(time - minutes * 60);
      s = s < 0 ? 0 : s;

      const seconds = s < 10 ? "0" + s : s;

      return { minutes, seconds };
    },
    async logoutAction() {
      logger.info("Logging user out!");
      await LogoutHlp(false, "/login");
      this.closeDialog();
      await this.$router.replace("/login");
    },
    hideDialog() {
      if (this.timeIsOut) {
        this.logoutAction();
        return;
      }

      try {
        this.authStore.refreshToken({});
      } catch {
        this.logoutAction();
        logger.info("Cannot refresh token");
      } finally {
        this.closeDialog();
      }
    },
    closeDialog() {
      logger.info("Closing dialog");

      this.authStore.hideSessionModal();
      this.clearTimer();
    },
    continueSession() {
      try {
        this.authStore.refreshToken({});
      } catch {
        logger.info("Cannot refresh token");
      }

      this.closeDialog();
    }
  }
});
</script>
