import type { AppControllerBoirForm } from "@/services/api";
import { AppControllerBoirFormCompletionStatusEnum, AppControllerBoirFormPaymentStatusEnum } from "@/services/api";
import { redirectToFiledirectForm } from "@/util/tracking";

import { useFilings } from "../filings/use-filings";
import { useEventBus } from "../use-event-bus";

interface UseBoirReturn {
  toBoirFiling(): void;
}

export const useBoir = (): UseBoirReturn => {
  const eventBus = useEventBus();
  const filings = useFilings();

  const toBoirFiling = (): void => {
    if (
      filings.thisYearBoirFilings.value.some((filing: AppControllerBoirForm) => {
        return (
          filing.completion_status === AppControllerBoirFormCompletionStatusEnum.Created ||
          (filing.completion_status === AppControllerBoirFormCompletionStatusEnum.Completed &&
            filing.payment_status !== AppControllerBoirFormPaymentStatusEnum.Paid)
        );
      })
    ) {
      eventBus.$emit("setShowConfirmBoirFilingModal", { to: true });
      return;
    }

    redirectToFiledirectForm();
  };

  return { toBoirFiling };
};
