import type { Agent } from "@fingerprintjs/fingerprintjs";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import * as Sentry from "@sentry/vue";
import { jwtDecode } from "jwt-decode";
import { defineStore } from "pinia";

import { Login, Logout, MagicLogin, RefreshToken, Registration } from "@/api/oauth";
import {
  ConsentTermsAndConditions,
  EmailConfirm,
  PasswordReset,
  UpdateEmail,
  UpdatePassword,
  UpdatePhone,
  UpdateUsername
} from "@/api/user";
import { cookieNames, TWENTY_MINUTES_IN_MS, WEEK_IN_MS } from "@/config/constants";
import type {
  DtoChangeEmailIntentRequest,
  DtoChangeEmailRequest,
  DtoChangePasswordRequest,
  DtoChangePhoneRequest,
  DtoChangeUsernameRequest,
  DtoForgotPasswordRequest,
  DtoLoginRequest,
  DtoMagicLoginRequest,
  DtoRefreshTokenResponse,
  DtoRegistrationRequest,
  UserConsentRequest
} from "@/services/api";
import { CommonProductFocus } from "@/services/api";
import { usePostHogStore } from "@/store/posthog.store";
import type { ExtJwtPayload, UserData } from "@/store/types";
import { resetStores } from "@/store/utils";
import { getCookie, setCookie } from "@/util/cookies";
import { getSHA1 } from "@/util/crypto";
import type { CatchError } from "@/util/error";
import { handleError } from "@/util/error";
import { isJwtToken } from "@/util/helpers";
import { getLogger } from "@/util/logger";
import { checkFunnelOrA16 } from "@/util/utils";

const logger = getLogger("Store: Auth");

const userData = getCookie<UserData>(cookieNames.userData);
const userUid = getCookie(cookieNames.userUid);
const deviceId = getCookie(cookieNames.deviceId);

export interface AuthState {
  userUid: string;
  userData: UserData;
  deviceId: string;
  trackingId: string | null;
  lockout: number | null;
  source: string | null;
  authToken: string;
  authTokenExp?: number;
  authTokenExpTimer: NodeJS.Timeout | null;
  authTokenExpShowDialog: boolean;
  authTokenExpShowExpMinutes: number;
  isTokenFetching: boolean;
}

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    userUid: userUid ?? "",
    userData: userData ?? {
      username: "",
      email: "",
      phone: "",
      first_name: "",
      last_name: "",
      source: "",
      id: "",
      funnel: "",
      product_focus: CommonProductFocus.ProductFocusNone
    },
    deviceId: deviceId ?? "",
    trackingId: null,
    lockout: null,
    source: null,
    authToken: "",
    authTokenExpTimer: null,
    authTokenExpShowDialog: false,
    authTokenExpShowExpMinutes: 10,
    isTokenFetching: false
  }),
  getters: {
    funnel(state): string {
      const userDataFunnel = checkFunnelOrA16(state.userData.funnel?.toUpperCase() ?? "");
      const funnelOverride = getCookie(cookieNames.funnel) ?? "";
      const cookieStorageDataFunnel = funnelOverride && checkFunnelOrA16(funnelOverride);

      // Never override A18 or A19 funnel
      if (["A18", "A19"].includes(userDataFunnel) && !["A18", "A19"].includes(cookieStorageDataFunnel)) {
        return userDataFunnel;
      }

      // Check if state already has funnel feature flag set
      if (cookieStorageDataFunnel) {
        return cookieStorageDataFunnel;
      }

      // return funnel that was chosen during registration or A16 if it's not yet set.
      return userDataFunnel ?? "A16";
    },
    registrationSource(state): string {
      if (state.userData?.source && state.userData.source !== "") {
        return `source=${state.userData.source}`;
      }

      return "";
    },
    tokenData(state): ExtJwtPayload | null {
      if (!state.authToken) return null;

      try {
        return jwtDecode<ExtJwtPayload>(state.authToken);
      } catch {
        return null;
      }
    },
    isAuthorized(state): boolean {
      // if (!state.loaded) return true;
      if (!state.authToken) return false;

      try {
        const tokenData: ExtJwtPayload | null = this.tokenData;

        return tokenData ? tokenData.exp * 1000 > +new Date() : false;
      } catch (e) {
        handleError(e);
        return false;
      }
    }
  },
  actions: {
    async logoutAndResetState() {
      await Logout();
      resetStores();
    },
    setAuthToken(authToken: string) {
      try {
        this.authToken = authToken;

        if (this.tokenData) {
          this.authTokenExp = this.tokenData.exp;
        }

        const expiresAt = new Date();
        expiresAt.setMinutes(expiresAt.getMinutes() + 20);

        setCookie(cookieNames.authTokenExp, expiresAt, TWENTY_MINUTES_IN_MS);
      } catch (e) {
        handleError(e, () => {
          // TODO: Handle error if jwt token is invalid
        });
      }
    },
    setUserData(payload: { data: Partial<UserData> }) {
      const { source } = this.userData;
      const { data } = payload;

      const postHogStore = usePostHogStore();

      this.userData = { ...this.userData, ...data, source };
      postHogStore.identify(this.userData.id, this.userData.email);

      setCookie(cookieNames.userData, this.userData, TWENTY_MINUTES_IN_MS);
    },
    setUserUid(uid: string) {
      this.userUid = uid;
      setCookie(cookieNames.userUid, uid, TWENTY_MINUTES_IN_MS);
    },
    authLock() {
      const lockTime = 15 * 60;
      const lockOut = this.lockout ?? 0;
      const now = new Date().getTime() / 1000;

      if (now - lockOut >= lockTime) {
        this.lockout = now;
      }
    },
    authUnlock() {
      this.lockout = null;
    },
    setDeviceId(id: string) {
      this.deviceId = id;

      setCookie(cookieNames.deviceId, id, WEEK_IN_MS);
    },
    setTrackingId(id: string) {
      this.trackingId = id;
    },
    setSource(source: string) {
      this.source = source;
    },
    hideSessionModal() {
      this.authTokenExpShowDialog = false;
    },
    idleTimeout(minutes: number = 10) {
      if (!this.isAuthorized) return;

      this.authTokenExpShowDialog = true;
      this.authTokenExpShowExpMinutes = minutes;
    },
    async updateDeviceId() {
      await FingerprintJS.load().then(async (agent: Agent) => {
        const { visitorId } = await agent.get();
        const fpSHA1 = await getSHA1(visitorId);

        this.setDeviceId(fpSHA1);
      });
    },
    async login(payload: { params: DtoLoginRequest }) {
      const { params } = payload;

      const loginResponse = await Login(params);
      const { profile, token_type, token } = loginResponse;

      if (token_type === "redirect") {
        window.location.href = `/land/login?t=${token}`;
        return;
      }

      const jwt_token = token;
      const currentScope = Sentry.getCurrentScope();

      currentScope.setTag("auth-source", "login");
      currentScope.setUser({
        id: profile.id,
        email: profile.email
      });

      resetStores();

      if (jwt_token) {
        this.setAuthToken(jwt_token);
      }

      this.setUserUid(profile.id);
      this.setUserData({ data: loginResponse.profile });

      return profile;
    },
    async register(payload: { params: DtoRegistrationRequest }) {
      const { params } = payload;

      const userData = await Registration(params);
      const { token, token_type } = userData;

      let { profile } = userData;

      if (token_type === "redirect") {
        window.location.href = `/land/login?t=${token}`;
        return;
      }

      let jwt_token = token;

      // Backend isn't configured properly or something else is wrong
      // so we need to exchange refresh token for jwt token ourselves
      if (!isJwtToken(jwt_token)) {
        console.log("refresh token is supplied instead of jwt token, let's exchange it");

        this.isTokenFetching = true;

        try {
          const userData: DtoRefreshTokenResponse | null = await RefreshToken(jwt_token);

          if (userData) {
            jwt_token = userData.jwt_token as string;
            profile = userData.profile;

            console.log("token exchange successful, continue as usual");
          } else {
            Sentry.captureException(new Error("Refresh token failed"));
            console.error("Refresh token failed");
          }
        } catch {
          Sentry.captureException(new Error("Refresh token failed"));
          console.error("Refresh token failed");
        } finally {
          this.isTokenFetching = false;
        }
      }

      const currentScope = Sentry.getCurrentScope();

      currentScope.setTag("auth-source", "secure-login");
      currentScope.setUser({
        id: profile.id,
        email: profile.email
      });

      resetStores();

      this.setAuthToken(jwt_token);
      this.setUserUid(profile.id);
      this.setUserData({ data: profile });
    },
    async refreshToken(payload?: { token?: string }) {
      const { token } = payload ?? {};

      logger.info("RefreshToken action call...");

      this.isTokenFetching = true;

      try {
        const userData: DtoRefreshTokenResponse | null = await RefreshToken(token);

        if (userData) {
          if (userData.jwt_token) this.setAuthToken(userData.jwt_token);

          this.setUserData({ data: userData.profile });
          this.setUserUid(userData.profile.id);

          const currentScope = Sentry.getCurrentScope();

          currentScope.setTag("auth-source", "secure-login");
          currentScope.setUser({
            id: userData.profile.id,
            email: userData.profile.email
          });

          return;
        }
      } catch (e: CatchError) {
        console.log("Refresh token error", e);
        Sentry.captureMessage(`Error trying to refresh token: ${JSON.stringify(e)}`);
        throw e;
      } finally {
        this.isTokenFetching = false;
      }
    },
    async passwordReset(params: DtoForgotPasswordRequest) {
      const data = await PasswordReset(params);

      logger.info("Password Reset done, here is");

      return data;
    },
    async magicLogin(payload: { params: DtoMagicLoginRequest }) {
      const { params } = payload;

      try {
        const { profile, token: jwt_token } = await MagicLogin(params);

        if (!jwt_token) return false;

        const currentScope = Sentry.getCurrentScope();

        currentScope.setTag("auth-source", "login");
        currentScope.setUser({
          id: profile.id,
          email: profile.email
        });

        resetStores();

        if (jwt_token) {
          this.setAuthToken(jwt_token);
        }

        this.setUserUid(profile.id);
        this.setUserData({ data: profile });

        return true;
      } catch {
        return false;
      }
    },
    async consentTermsAndConditions(params: UserConsentRequest) {
      const data = await ConsentTermsAndConditions(params);

      return data;
    },
    async emailConfirm(params: DtoChangeEmailRequest) {
      await EmailConfirm(params);

      resetStores();

      this.setAuthToken(params.token);
    },
    updateUserNames(payload: { params: { first_name: string; last_name: string } }) {
      const { params } = payload;
      const userData = { ...this.userData, ...params };

      this.setUserData({ data: userData });
    },

    async updateUsername(payload: { params: DtoChangeUsernameRequest }) {
      const { params } = payload;

      await UpdateUsername(params);

      const userData = { ...this.userData, username: params.new_user_name };

      this.setUserData({ data: userData });
    },

    async updateEmail(payload: { params: DtoChangeEmailIntentRequest }) {
      const { params } = payload;

      await UpdateEmail(params);

      const userData = { ...this.userData, email: params.email };

      this.setUserData({ data: userData });
    },
    async updatePhone(payload: { params: DtoChangePhoneRequest }) {
      const { params } = payload;

      await UpdatePhone(params);

      const userData = { ...this.userData, phone: params.phone };

      this.setUserData({ data: userData });
    },
    async updatePassword(params: DtoChangePasswordRequest) {
      await UpdatePassword(params);
    }
  }
});
