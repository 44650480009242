<template>
  <inline-svg :src="svgPath" :aria-label="ariaLabel" :unique-ids="true" :class="{ 'svg-icon': icon }" />
</template>
<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import InlineSvg from "vue-inline-svg";

import { useAppEngine } from "@/composables/use-app-engine";

export default defineComponent({
  name: "SvgIcon",
  components: {
    InlineSvg
  },
  props: {
    name: {
      type: String as PropType<string | null>,
      required: false,
      validator: (value: string) => !value.startsWith("/"),
      default: null
    },
    path: {
      type: String as PropType<string>,
      required: false
    },
    icon: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true
    },
    ariaLabel: {
      type: String as PropType<string>,
      required: false
    }
  },
  setup() {
    const { isFileSmartEngine } = useAppEngine();

    return { isFileSmartEngine };
  },
  computed: {
    computedName(): string {
      const brandedArrows = [
        "arrow-left-stroke",
        "arrow-right-stroke",
        "processed/list-ok",
        "processed/list",
        "processed/government",
        "processed/government-ok",
        "bbb-circled",
        "million",
        "authorized",
        "locked"
      ];

      const postfix = this.name && this.isFileSmartEngine && brandedArrows.includes(this.name) ? "-filesmart" : "";

      return `${this.name}${postfix}`;
    },
    svgPath(): string {
      if (this.path) return this.path;

      return `/assets/svg/${this.computedName}.svg`;
    }
  }
});
</script>
<style lang="scss" src="./styles.scss" scoped></style>
