import type { PostHog } from "posthog-js";
import posthog from "posthog-js";
import type { App } from "vue";

import { cookieNames, isPosthogEnabled, THREE_DAYS_IN_MS } from "@/config/constants";
import { usePostHogStore } from "@/store/posthog.store";
import { getCookie, removeCookie, setCookie } from "@/util/cookies";

export const initPosthog = (app: App) =>
  app.use({
    install(_options) {
      if (!isPosthogEnabled()) {
        return;
      }
      posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
        api_host: import.meta.env.VITE_POSTHOG_HOST,
        loaded: onPostHogLoaded
      });
    }
  });

const onPostHogLoaded = (phInstance: PostHog) => {
  const searchParams = new URLSearchParams(window.location.search);

  // If the ingest_id is present, identify with the ingest_id
  if (searchParams.has("ingest_id")) {
    console.log("cross domain ingest_id", searchParams.get("ingest_id"));
    phInstance.identify(searchParams.get("ingest_id") ?? undefined);
  }

  // If the ingest_id is not present, try to identify with the ingest_id from cookies
  if (!searchParams.has("ingest_id")) {
    const cookiesIngestId = getCookie(cookieNames.ingestId);
    if (cookiesIngestId) {
      console.log("ingest_id from cookies: ", cookiesIngestId);
      phInstance.identify(cookiesIngestId ?? undefined);
      removeCookie(cookieNames.ingestId);
    }
  }

  const ingestId = phInstance.get_distinct_id();
  setCookie(cookieNames.ingestId, ingestId, THREE_DAYS_IN_MS);

  const postHogStore = usePostHogStore();
  postHogStore.initialize(phInstance);
};
