import * as Sentry from "@sentry/vue";

import { OAuthAPI } from "@/api";
import { cookieNames, NO_AUTH_HEADER, TEN_MINUTES_IN_MS } from "@/config/constants";
import type {
  AuthVerifySecondFactorRequest,
  DtoLoginRequest,
  DtoLoginResponse,
  DtoMagicLinkRequest,
  DtoMagicLoginRequest,
  DtoRefreshTokenResponse,
  DtoRegistrationRequest,
  ModelsSecondFactorType
} from "@/services/api";
import { CommonProductFocus } from "@/services/api";
import { useAuthStore } from "@/store/auth.store";
import { getCookie } from "@/util/cookies";
import { isAxiosError } from "@/util/error";
import { getEngineSource, nullToEmptyString } from "@/util/helpers";
import { getLogger } from "@/util/logger";

const log = getLogger("API: OAuth");

const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const logout = () => {
  const pathname = window.location.pathname;

  if (pathname !== "/session-expired") {
    window.location.replace("/session-expired");
  }
};

export const Registration = async (params: DtoRegistrationRequest): Promise<DtoLoginResponse> => {
  log.time("Registration call");

  const { data } = await OAuthAPI.registerUser(params, NO_AUTH_HEADER);

  log.timeEnd("Registration call");

  return data;
};

export const Login = async (params: DtoLoginRequest): Promise<DtoLoginResponse> => {
  log.time("Login call");

  const { data } = await OAuthAPI.loginUser(params, NO_AUTH_HEADER);

  log.timeEnd("Login call");

  return data;
};

export const MagicLoginIntent = async (params: DtoMagicLinkRequest) => {
  log.time("MagicLinkIntent");
  try {
    await OAuthAPI.sendMagicLink(params, NO_AUTH_HEADER);
    return Promise.resolve(true);
  } catch (ex) {
    return Promise.resolve((ex as any)?.response?.data?.msg);
  } finally {
    log.timeEnd("MagicLinkIntent");
  }
};

export const MagicLogin = async (params: DtoMagicLoginRequest): Promise<DtoLoginResponse> => {
  log.time("MagicLogin");
  const { data } = await OAuthAPI.magicLoginUser(params, NO_AUTH_HEADER);
  log.timeEnd("MagicLogin");
  return data;
};

export const Logout = async (): Promise<string> => {
  log.time("Logout call");

  const { data } = await OAuthAPI.logout(NO_AUTH_HEADER);

  log.timeEnd("Logout call");

  return data;
};

export const RefreshToken = async (refreshToken?: string): Promise<DtoRefreshTokenResponse | null> => {
  log.info("RefreshTokenAPI call");
  let tryCount = 0;
  let sleepMs = 200;
  const options = {
    headers: {
      "X-Skip-Retry": true
    },
    timeout: TEN_MINUTES_IN_MS
  };

  while (tryCount < 5) {
    try {
      const authStore = useAuthStore();
      // Refresh token param only for satelites, otherwise it uses refresh-token from cookies
      const { data } = await OAuthAPI.refreshJWTToken(refreshToken, options);

      authStore.setUserUid(data.profile.id);

      return data;
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.response?.status === 429) {
          await sleep(sleepMs);
          return null;
        } else {
          if (e.message !== "Network Error") {
            Sentry.captureMessage(
              `Error refreshing page - user was redirected to /session-expired e:  ${JSON.stringify(e)}`
            );
            logout();
          }

          throw e;
        }
      }

      sleepMs += 500;
      tryCount++;
      log.warn("Retrying RefreshToken call after sleep: " + sleepMs + " / " + tryCount + " tries");
    }
  }

  if (tryCount === 5) {
    logout();
  }

  return null;
};

export const startOAuthPath = (
  funnel: string,
  provider: string,
  searchSource: string,
  deviceId: string,
  productFocus: CommonProductFocus = CommonProductFocus.ProductFocusExtension
): string => {
  const rdtkclid: string = nullToEmptyString(getCookie(cookieNames.redtrackClickId));
  const currentPageSearchParams = Object.fromEntries(new URL(window.location.href).searchParams);

  //TODO: add other parameters that may be required. #1303
  const url = new URL(`/api/v1/oauth/via/${provider}`, window.location.origin);
  url.searchParams.set("source", getEngineSource());
  url.searchParams.set("device_id", deviceId);
  url.searchParams.set("search_source", searchSource);
  url.searchParams.set("funnel", funnel);
  url.searchParams.set("rdtkclid", rdtkclid);
  url.searchParams.set("product_focus", productFocus);

  if (currentPageSearchParams.flow && currentPageSearchParams.plan) {
    url.searchParams.set("flow", currentPageSearchParams.flow);
    url.searchParams.set("plan", currentPageSearchParams.plan);
    url.searchParams.set("product_focus", CommonProductFocus.ProductFocusDFY);
  }

  return url.toString();
};

export const requestSecondFactor = async (factor: ModelsSecondFactorType) => {
  log.time("Second Factor call");

  const { data } = await OAuthAPI.requestSecondFactor({ second_factor_type: factor });

  log.timeEnd("Second Factor call");

  return data;
};

export const verifySecondFactor = async (params: AuthVerifySecondFactorRequest) => {
  log.time("Second Factor Verify call");

  const { data } = await OAuthAPI.verifySecondFactor(params);

  log.timeEnd("Second Factor Verify call");

  return data;
};
