import * as Sentry from "@sentry/vue";
import { defineStore } from "pinia";

import { diyFilingIdPut, diyTaxFilingPost, getDiySettings } from "@/api/diy";
import { eventBus } from "@/composables/use-event-bus";
import { cookieNames, HOUR_IN_MS } from "@/config/constants";
import { router } from "@/router";
import {
  CommonProductTypes,
  CommonSubscriptionStatus,
  type DiyDisclosureParty,
  type DiyNewTaxFilingRequest,
  type DiyTaxReturn
} from "@/services/api";
import { useAuthStore } from "@/store/auth.store";
import { useCartStore } from "@/store/cart.store";
import { useFormsStore } from "@/store/forms.store";
import type { UserData } from "@/store/types";
import { getAllCookieKeys, getCookie, removeCookie, setCookie } from "@/util/cookies";
import { isSafari } from "@/util/helpers";
import { getLogger } from "@/util/logger";
import { ROUTE_NAMES } from "@/util/routes/route-names";

export interface DiyStore {
  consentToDiscloseId?: string | null;
  consentToUseId: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  disclosureToCompanies: DiyDisclosureParty[] | null;
  isConsentToUseRequired: boolean | null;
  isConsentToDisclosureRequired: boolean | null;
  isNewAvailable: boolean | null;
  diyReturnSettingsLoaded: boolean;
  lastDiyProductSku: string | null;
  dashboardModalsState: {
    upgradeToDiy: boolean;
    diyAccess: boolean;
    secondaryPurchaseConsent: boolean;
    reactivateMembership: boolean;
    reactivateDiy: boolean;
  };
}

export enum DashboardModalsStates {
  UpgradeToDiy = "upgradeToDiy",
  DiyAccess = "diyAccess",
  SecondaryPurchaseConsent = "secondaryPurchaseConsent",
  ReactivateMembership = "reactivateMembership",
  ReactivateDiy = "reactivateDiy"
}

type PersistedStoreKeys = "consentToDiscloseId" | "consentToUseId";

type RedirectMobileCondition =
  | "hasThisTaxYearDiyReturn"
  | "isNewAvailable"
  | "hasActiveFilesmart"
  | "hasExpiredFilesmart"
  | "defaultAction";

export const getDiyStoreDataCookieName = (): string => {
  const userData = getCookie<UserData>(cookieNames.userData);
  const userId = userData?.id ?? "guest";
  return `${cookieNames.diyStoreData}-${userId}`;
};

const logger = getLogger("Store: Diy");

export const removeOtherAccountsDiyStoreData = () => {
  const currentAccountCookieKey = getDiyStoreDataCookieName();
  const cookieKeys = getAllCookieKeys();

  cookieKeys.forEach((key) => {
    if (key.startsWith(cookieNames.diyStoreData) && key !== currentAccountCookieKey) {
      removeCookie(key);
    }
  });
};

const cleanSavedStoreData: Pick<DiyStore, PersistedStoreKeys> = {
  consentToDiscloseId: null,
  consentToUseId: null
};

const cleanDynamicStoreData: Omit<DiyStore, PersistedStoreKeys> = {
  email: null,
  firstName: null,
  lastName: null,
  phone: null,
  disclosureToCompanies: null,
  isConsentToUseRequired: null,
  isConsentToDisclosureRequired: null,
  isNewAvailable: null,
  diyReturnSettingsLoaded: false,
  lastDiyProductSku: null,
  dashboardModalsState: {
    upgradeToDiy: false,
    diyAccess: false,
    secondaryPurchaseConsent: false,
    reactivateMembership: false,
    reactivateDiy: false
  }
};

const savedDiyStoreData =
  getCookie<Pick<DiyStore, PersistedStoreKeys>>(getDiyStoreDataCookieName()) ?? cleanSavedStoreData;
removeOtherAccountsDiyStoreData();
const diyStoreData: DiyStore = {
  ...savedDiyStoreData,
  ...cleanDynamicStoreData
};

export const useDiyStore = defineStore("diy", {
  state: (): DiyStore => diyStoreData,
  actions: {
    setState(hintData: Partial<DiyStore>) {
      Object.keys(hintData).forEach((key) => {
        this[key] = hintData[key];
      });

      setCookie(
        getDiyStoreDataCookieName(),
        {
          consentToDiscloseId: this.consentToDiscloseId,
          consentToUseId: this.consentToUseId
        },
        HOUR_IN_MS
      );
    },
    async getDiyReturnSettings(force?: boolean) {
      if (this.diyReturnSettingsLoaded && !force) return;

      try {
        const {
          client_hint,
          disclosure_to_companies: disclosureToCompanies,
          is_consent_to_use_required: isConsentToUseRequired,
          is_consent_to_disclosure_required: isConsentToDisclosureRequired = null,
          is_new_available: isNewAvailable = null
        } = await getDiySettings();

        const {
          email = null,
          phone = null,
          first_name: firstName = null,
          last_name: lastName = null
        } = client_hint ?? {};

        this.setState({
          email,
          phone,
          firstName,
          lastName,
          disclosureToCompanies,
          isConsentToUseRequired,
          isConsentToDisclosureRequired,
          isNewAvailable
        });

        this.diyReturnSettingsLoaded = true;
      } catch (e) {
        Sentry.captureMessage(`Error trying to get diy hints: ${JSON.stringify(e)}`);
      }
    },
    setDashboardModalState(modalName: DashboardModalsStates, to: boolean) {
      this.dashboardModalsState[modalName] = to;
    },
    closeAllDashboardModals() {
      Object.keys(this.dashboardModalsState).forEach((key) => (this.dashboardModalsState[key] = false));
    },
    async redirectToCreateDiy() {
      await this.getDiyReturnSettings();

      if (this.isConsentToUseRequired) {
        router.push({ name: ROUTE_NAMES.consentToUse });
        return;
      }

      if (this.isConsentToDisclosureRequired) {
        router.push({ name: ROUTE_NAMES.consentToDisclosure });
        return;
      }

      try {
        await this.createFiling();
      } catch {
        router.push({ name: ROUTE_NAMES.dashboardIndex });
      }
    },
    async showAppropriateModal(stepsToSkip: RedirectMobileCondition[] = []) {
      await this.getDiyReturnSettings();
      const formStore = useFormsStore();

      if (!stepsToSkip.includes("hasThisTaxYearDiyReturn") || !stepsToSkip.includes("isNewAvailable")) {
        await formStore.getDiyReturns();
      }

      const actions: Record<RedirectMobileCondition, () => void> = {
        hasThisTaxYearDiyReturn: () => this.setDashboardModalState(DashboardModalsStates.DiyAccess, true),
        isNewAvailable: () => this.redirectToCreateDiy(),
        hasActiveFilesmart: () => this.setDashboardModalState(DashboardModalsStates.SecondaryPurchaseConsent, true),
        hasExpiredFilesmart: () => this.setDashboardModalState(DashboardModalsStates.ReactivateMembership, true),
        defaultAction: () => this.setDashboardModalState(DashboardModalsStates.ReactivateDiy, true)
      };

      const conditions: Record<RedirectMobileCondition, boolean> = {
        hasThisTaxYearDiyReturn: this.hasThisTaxYearDiyReturn,
        isNewAvailable: Boolean(this.isNewAvailable),
        hasActiveFilesmart: this.hasActiveFilesmart,
        hasExpiredFilesmart: this.hasExpiredFilesmart,
        defaultAction: true
      };

      const condition =
        (Object.keys(conditions) as RedirectMobileCondition[])
          .filter((key) => !stepsToSkip.includes(key))
          .find((key) => conditions[key]) ?? "defaultAction";

      actions[condition]();
    },
    async handleNewReturn() {
      await this.showAppropriateModal(["hasThisTaxYearDiyReturn", "isNewAvailable"]);
    },
    async editFiling(filingId: string): Promise<DiyTaxReturn | null> {
      try {
        logger.info("Edit filing action call...");
        return await diyFilingIdPut(filingId);
      } catch (e) {
        Sentry.captureMessage(`Error trying to edit filing: ${JSON.stringify(e)}`);
        return null;
      }
    },
    async createFiling() {
      const authStore = useAuthStore();
      const secondFactorConfirmed = authStore.userData.second_factor_confirmed;
      await this.getDiyReturnSettings();

      if (!secondFactorConfirmed) {
        router.push({ name: ROUTE_NAMES.diyVerification });
        return;
      }

      const userDataPhone = authStore.userData.phone ?? "";
      const userDataEmail = authStore.userData.email ?? "";
      // Using || intentionally to handle empty string cases
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      const phone = this.phone || userDataPhone;
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      const email = this.email || userDataEmail;

      const request: DiyNewTaxFilingRequest = {
        consent_to_disclose_id: this.consentToDiscloseId ?? undefined,
        consent_to_use_id: this.consentToUseId ?? undefined,
        first_name: String(this.firstName),
        last_name: String(this.lastName),
        email: String(email),
        phone: `+1${String(phone)}`
      };

      const { id } = await diyTaxFilingPost(request);
      if (!id) {
        const sentryReport = {
          first_name: request.last_name,
          last_name: request.last_name,
          phone: request.phone
        };
        Sentry.captureMessage(`Error trying to verify account: ${JSON.stringify(sentryReport)}`);
        router.push({ name: ROUTE_NAMES.dashboardIndex });
      }

      const diyLink = `/return/diy/${id}`;

      await this.redirect(diyLink);

      if (this.lastDiyProductSku && !isSafari()) {
        eventBus.$emit("setShowOrderSuccessModal", { to: true, source: this.lastDiyProductSku });
        this.setState({ lastDiyProductSku: null });
      }
    },
    async redirect(link: string) {
      const newWindow = window.open(link, "_blank");

      if (newWindow === null) {
        // If window.open returns null, it was likely blocked
        window.location.href = link;
        return;
      }

      await router.push({ name: ROUTE_NAMES.dashboardIndex });
    },
    editLastFiling() {
      const lastFiling = this.filings.at(-1);
      if (!lastFiling) {
        return;
      }

      const authStore = useAuthStore();

      if (authStore.userData.second_factor_confirmed) {
        window.open(`/return/diy/${lastFiling.id}`, "_blank");
        this.closeAllDashboardModals();
        return;
      }

      router.push(`/return/diy/new/verify-account?id=${lastFiling.id}`);
      this.closeAllDashboardModals();
    }
  },
  getters: {
    fullName(state): string | null {
      if (!state.firstName && !state.lastName) {
        return null;
      }

      if (!state.firstName) {
        return state.lastName;
      }

      if (!state.lastName) {
        return state.firstName;
      }

      return `${state.firstName} ${state.lastName}`;
    },
    hasThisTaxYearDiyReturn(): boolean {
      const formState = useFormsStore();
      const taxYear = new Date().getFullYear() - 1;
      return formState.diyReturns.some((filing) => filing.tax_year === taxYear && filing.id);
    },
    hasActiveFilesmart(): boolean {
      const formsStore = useFormsStore();

      return formsStore.subscriptionStatuses.some(
        (subscription) =>
          (subscription.subscription_status === CommonSubscriptionStatus.SubscriptionStatusYes ||
            subscription.subscription_status === CommonSubscriptionStatus.SubscriptionStatusPaymentFailed) &&
          subscription.subscription_type === CommonProductTypes.ProductTypesBundle
      );
    },
    hasExpiredFilesmart(): boolean {
      const cartStore = useCartStore();
      const formsStore = useFormsStore();

      return (
        // searching for fact that user had filesmart that is expired and double check that no active subscriptions exist now
        cartStore.purchases.some(
          (purchase) =>
            purchase.product_status === CommonSubscriptionStatus.SubscriptionStatusExpired &&
            purchase.product_type === CommonProductTypes.ProductTypesBundle
        ) &&
        !formsStore.subscriptionStatuses.some(
          (subscription) => subscription.subscription_type === CommonProductTypes.ProductTypesBundle
        )
      );
    },
    filings() {
      const formState = useFormsStore();
      return formState.diyReturns;
    }
  }
});
