/* eslint-disable @typescript-eslint/prefer-promise-reject-errors */
import * as Sentry from "@sentry/vue";
import type { InternalAxiosRequestConfig, RawAxiosRequestHeaders } from "axios";
import axios from "axios";

import { API_BASE } from "@/config/constants";
import { tricklingProgress } from "@/plugins/trickling";
import { isRouteSetUsername, router } from "@/router";
import {
  BOIRApi,
  BusinessFormsApi,
  Configuration,
  ConsentsApi,
  DFYReturnsApi,
  DIYFilingsApi,
  DocumentsApi,
  FormHelpersApi,
  ManagementApi,
  OAuthApi,
  PaymentsApi,
  PersonalFormsApi,
  PlannerApi,
  PurchasesApi,
  SubscriptionsApi,
  UserApi,
  UserProfileApi,
  UserUtilsApi,
  UtilsApi,
  VaultApi
} from "@/services/api";
import type { BaseAPI } from "@/services/api/base";
import { useAuthStore } from "@/store/auth.store";
import { useAppStateStore } from "@/store/state.store";
import { LogoutHlp } from "@/util/auth";

let isAlreadyFetchingAccessToken: boolean = false;
let subscribers: Array<(token: string) => void> = [];

const basePath = import.meta.env.MODE !== "production" ? "https://localhost:8080/api/v1" : API_BASE;

const configuration = new Configuration({
  accessToken: () => {
    const authStore = useAuthStore();

    return authStore.authToken;
  }
});

const onAccessTokenFetched = (token: string) => {
  subscribers.forEach((callback) => callback(token));
  subscribers = [];
};

const addSubscriber = (callback: (token: string) => void) => {
  subscribers.push(callback);
};
const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(async (config): Promise<InternalAxiosRequestConfig> => {
  const appStateStore = useAppStateStore();
  const authStore = useAuthStore();

  appStateStore.setLoading(true);
  tricklingProgress.start();

  // Skip auth check if header is present
  if (config.headers["X-No-Auth"]) {
    return config;
  }

  let { authToken } = authStore;
  const { tokenData } = authStore;
  const currentTimestamp = Number(new Date());
  const expirationTimestamp = tokenData ? Number(new Date(tokenData.exp * 1000)) : Infinity;

  // If no auth token exists, try to refresh
  if ((!authToken || currentTimestamp > expirationTimestamp) && !config.headers["X-Skip-Retry"]) {
    if (!isAlreadyFetchingAccessToken) {
      try {
        // Attempt to refresh the token
        isAlreadyFetchingAccessToken = true;
        await authStore.refreshToken();
        isAlreadyFetchingAccessToken = false;
        authToken = authStore.authToken; // Get the new token

        if (authToken) {
          onAccessTokenFetched(authToken);
        } else {
          appStateStore.setLoading(false);
          tricklingProgress.done();

          return Promise.reject({
            response: {
              status: 401,
              data: {
                msg: "Unable to authenticate"
              }
            }
          });
        }
      } catch {
        isAlreadyFetchingAccessToken = false;
        appStateStore.setLoading(false);
        tricklingProgress.done();

        return Promise.reject({
          response: {
            status: 401,
            data: {
              msg: "Failed to refresh authentication"
            }
          }
        });
      }
    } else {
      // Wait for the token to be refreshed
      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((token: string) => {
          config.headers.Authorization = `Bearer ${token}`;
          resolve(config);
        });
      });

      return retryOriginalRequest as Promise<InternalAxiosRequestConfig>;
    }
  }

  // Add auth token if it exists
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  return config;
});

axiosApiInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  if (!config.headers["X-Skip-Loading-Indicator"]) {
    const appStateStore = useAppStateStore();

    appStateStore.setLoading(true);
    tricklingProgress.start();
  }

  return config;
});

axiosApiInstance.interceptors.response.use(
  (response) => {
    const appStateStore = useAppStateStore();

    appStateStore.setLoading(false);
    tricklingProgress.done();

    return response;
  },
  async (error) => {
    const appStateStore = useAppStateStore();
    const authStore = useAuthStore();

    appStateStore.setLoading(false);

    tricklingProgress.done();

    const { config, response } = error;

    /**
     * @TODO
     * Remove when fixed
     * https://github.com/axios/axios/issues/5089
     */
    config.headers = JSON.parse(JSON.stringify(config.headers || {})) as RawAxiosRequestHeaders;

    const scope = new Sentry.Scope();
    scope.setTag("activity", "auth");

    if (!response && error.message === "Network Error") {
      error.response = {
        data: {
          msg: "Network Error. Please check your connection"
        }
      };

      return Promise.reject(error);
    }

    if (!response) {
      return Promise.reject(error);
    }

    if (response.status === 401) {
      await LogoutHlp(false, "/session-expired");
      window.location.replace("/session-expired");
    }

    if (response.status === 403) {
      const tokenData = authStore.tokenData;
      const scopes = tokenData?.scopes ?? [];

      if (scopes.includes("consent") && !isRouteSetUsername()) {
        router.replace({ path: "/registration/create-username" });
      }
    }

    if (response.status === 422) {
      router.replace({ path: "/login" });
      Sentry.captureMessage(`422 error happened. Response: ${error}`, scope);
    }

    return Promise.reject(error);
  }
);

const createApi = <T extends BaseAPI>(apiObject: new (...params: ConstructorParameters<typeof BaseAPI>) => T): T => {
  return new apiObject(configuration, basePath, axiosApiInstance);
};

export const DocumentsAPI = createApi(DocumentsApi);
export const PersonalFormsAPI = createApi(PersonalFormsApi);
export const BusinessFormsAPI = createApi(BusinessFormsApi);
export const FormHelpersAPI = createApi(FormHelpersApi);
export const ManagementAPI = createApi(ManagementApi);
export const OAuthAPI = createApi(OAuthApi);
export const PaymentsAPI = createApi(PaymentsApi);
export const PurchasesAPI = createApi(PurchasesApi);
export const SubscriptionsAPI = createApi(SubscriptionsApi);
export const PlannerAPI = createApi(PlannerApi);
export const DFYReturnsAPI = createApi(DFYReturnsApi);
export const VaultAPI = createApi(VaultApi);
export const UserUtilsAPI = createApi(UserUtilsApi);
export const UserProfileAPI = createApi(UserProfileApi);
export const UserAPI = createApi(UserApi);
export const UtilsAPI = createApi(UtilsApi);
export const DIYFilingsAPI = createApi(DIYFilingsApi);
export const ConsentsAPI = createApi(ConsentsApi);
export const BoirAPI = createApi(BOIRApi);
