<template>
  <header class="app-header app-header-filesmarttax">
    <b-navbar toggleable="lg" container>
      <b-navbar-brand :class="{ center: !showLinks }">
        <router-link :to="extensionUrl" exact>
          <logo />
        </router-link>
      </b-navbar-brand>
      <b-navbar-toggle v-if="showLinks && !isHome" target="nav-collapse">
        <div class="hamburger hamburger-slider">
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
        </div>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse-top" is-nav>
        <b-navbar v-if="showLinks && !isHome" toggleable="lg" class="navbar-main mx-auto">
          <div class="container">
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="mx-auto">
                <b-nav-item to="/" exact>Home</b-nav-item>
                <b-nav-item target="_blank" href="https://blog.file-tax.net">Blog</b-nav-item>
                <b-nav-item to="/faq">FAQ</b-nav-item>
                <b-nav-item v-if="authorized" to="/dashboard">Dashboard</b-nav-item>
                <b-nav-item to="/contact-us">Contact Us</b-nav-item>
                <b-nav-item href="/terms-of-use">Terms of Use</b-nav-item>
                <b-nav-item href="/privacy-policy">Privacy Policy</b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </b-collapse>

      <router-link v-if="hideSubmitExtBtn && !authorized" to="/login" class="app-header-login-link" exact>
        <svg-icon name="account-stroke" />
        Login
      </router-link>
    </b-navbar>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import logo from "@/components/logo/index.vue";
import SvgIcon from "@/components/svg-icon/index.vue";

export default defineComponent({
  name: "FilesmartTaxHeader",
  components: {
    SvgIcon,
    logo
  },
  props: {
    authorized: {
      type: Boolean,
      default: false
    },
    showLinks: {
      type: Boolean,
      default: true
    },
    extensionUrl: {
      type: String,
      required: true
    },
    blogUrl: {
      type: String,
      required: false
    },
    hideSubmitExtBtn: {
      type: Boolean,
      default: false
    },
    isHome: {
      type: Boolean,
      default: false
    }
  }
});
</script>
